import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import Layout from '../components/Layout'
import Pagination from '../components/Pagination'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'

const Title = styled.h2`
  font-size: 1.5em;
  line-height: 1;
  font-family: ${(props) => props.theme.fonts.title};
  // @media screen and (max-width: ${(props) => props.theme.responsive.medium}) {
  //   font-size: 1.5em;
  // }
  color: dimgray;

  hr {
    border-style: solid;
    border-color: ${(props) => props.theme.colors.secondary};
    // width: ${(props) => props.theme.responsive.medium};
    // margin: 2em auto 1em;
    // margin: 2em 0em 0.5em 0em;
    display: block;
    // margin-bottom: 0em;
  }
`

const Sep = styled.p`
  font-family: ${(props) => props.theme.fonts.title};
  font-size: 1.5em;
  color: dimgray;
  text-align: center;
`

const List = styled.ul`
  margin: 0 auto;
  &::after {
    content: '';
    flex: 0 0 33%;
  }
`

const Note = styled.li`
  margin: 0 0 4em 0;
  width: 100%;
`
const Date = styled.p`
  // display: inline-block;
  color: gray;
  font-style: italic;
  font-size: 1em;
  // line-height: 1.5;
  margin-bottom: 1em;
`

const Source = styled.p`
  text-align: right;
  font-style: italic;
  color: DimGray;
  font-size: 1.1em;
`

const Notes = ({ data, pageContext }) => {
  const notes = data.allContentfulNote.edges
  // const { humanPageNumber, basePath } = pageContext

  return (
    <Layout>
      <SEO title="Notes" description="Notes" />
      <Container>
        <PageTitle>Notes</PageTitle>
        <List>
          {notes.map(({ node: note }) => (
            <Note key={note.id}>
              <Title>
                <hr></hr>
                {/* <Sep> *** </Sep> */}
                {note.title}
              </Title>
              <Date> {note.publishDate} </Date>
              <PageBody body={note.body}/>
              <Source> {note.source} </Source>
            </Note>
          ))}
        </List>
      </Container>
      <Pagination context={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulNote(
      sort: { fields: [publishDate], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          publishDate
          title
          body {
            childMarkdownRemark {
              html
            }
          }
          source
          id
        }
      }
    }
  }
`

export default Notes
